import React from 'react'
import Login from '../../component/AdminCompo/Login'

export default function Adminlogin() {
  return (
    <div>
      
      <Login/>
    </div>
  )
}
