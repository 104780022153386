import React from 'react'
import Home from '../component/Home'

export default function HomePage() {
  return (
    <div>
        <div>
            <Home/>
        </div>
      
    </div>
  )
}
