import React from 'react'
import AdminNav from '../../component/AdminCompo/AdminNav'

export default function AdminSkills() {
  return (
    <div className='flex'>
      <div className=''>
        <AdminNav/>
      </div>
      <div className='w-screen'>
        <h1>hi</h1>
      </div>
    </div>
  )
}
